//**************Docomo Digital**************

// Common code used by all instances.

//**************Change and refactor carefully****************

var all_common = all_common || {};

// region RegionManager

all_common.addRegions = function(regions) {
  if (all_common.rm == null) all_common.rm = new Marionette.RegionManager();
  return all_common.rm.addRegions(regions);
};

// endregion RegionManager

// region Data storage

all_common.data = function(index, value, buttonProps) {
  all_common.dataSet = all_common.dataSet || [];
  var res = null;
  if (index >= 0) {
    if (typeof value !== 'undefined') {
      all_common.dataSet[index] = all_common.dataSet[index] || {};
      $.extend(all_common.dataSet[index], value);
      if (typeof buttonProps !== 'undefined') {
        buttonProps['data-index'] = index;
        res = $('<button/>', buttonProps).prop('outerHTML');
      }
    }
    else {
      res = all_common.dataSet[index];
    }
  }
  return res;
};

//Predifined button props to make code more DRY

all_common.predifinedData = function(index, data, type) {
  var buttonProps = {};
  var dataSet = {};
  var title = '';
  dataSet[type] = data;
  switch (type) {
    case 'refund_sub':
      title = bg_globals.i18n.table.actions && bg_globals.i18n.table.actions.refund_subscription
          ? bg_globals.i18n.table.actions.refund_subscription
          : '';
      buttonProps = {
        'class': 'byg_tbut byg_tbut--refund',
        'title': title,
      };
      break;
    case 'refund_trx':
      title = bg_globals.i18n.table.actions && bg_globals.i18n.table.actions.refund_transaction
          ? bg_globals.i18n.table.actions.refund_transaction
          : '';
      buttonProps = {
        'class': 'byg_tbut byg_tbut--refund',
        'title': title,
      };
      break;
    case 'unsubscribe':
      title = bg_globals.i18n.table.actions && bg_globals.i18n.table.actions.unsubscribe_subscription
          ? bg_globals.i18n.table.actions.unsubscribe_subscription
          : '';
      buttonProps = {
        'class': 'byg_tbut byg_tbut--unsub',
        'title': title,
      };
      break;
    default:
      console.warn('No predifined Button Props found!');
      break;
  }
  return all_common.data(index, dataSet, buttonProps);
};

// endregion Data storage

// region Details two columns formatting

all_common.formatDetails = function($details, noEmptyClean) {
  // remove all empty rows
  if (!noEmptyClean) {
    $details.find('.byg_private__det--val').each(function() {
      if (!$.trim($(this).html())) {
        console.log($(this).closest('.row').find(".byg_private__det--prop").html() + " - is empty so hidden from details");
        $(this).closest('.row').remove();
      }
    });
  }

  // move half of rows to other column
  var $overflowContainer = $details.find('.js__det--overflowContainer');
  var $rows = $details.find('.byg_private__detailsWrap').find('.row');
  var breakpoint = Math.ceil($rows.length / 2);
  $rows.each(function(index) {
    if (index >= breakpoint) {
      var elem = $(this).detach();
      $overflowContainer.append(elem);
    }
  });

};

// endregion Details two columns formatting

// region Details long string ellipsing

all_common.ellipseDetails = function($details) {
  $details.find('.long').each(function() {
    var text = $(this).html();
    if (text.length > 33) {
      $(this).addClass("byg_private__det--long");
      var ellipsedText = text.substring(0, 30) + "...";
      $(this).html('<a class="popoverIcon" data-toggle="popover" data-content=""><i class="fa fa-eye" aria-hidden="true"></i></a>');
      $(this).find(".popoverIcon").attr("data-content", text).prepend(ellipsedText);
    }
  });
};

// endregion Details long values elipsing

// region DataTables utils
all_common.hideDatatable = function() { all_common.hide('.byg_private__dtWrap, .byg_private__formWrap '); };
all_common.showDatatable = function() {
  all_common.show('.byg_private__dtWrap, .byg_private__formWrap ');
  all_common.responiveRecalc();
};

all_common.reloadDatatables = function(e, datatables, callback) {
  if (e) e.preventDefault();
  all_common.hideMobileKeybord();
  datatables.ajax.reload(function() {
    all_common.responiveRecalc();
    if (typeof callback === 'function') callback();
  });
};

all_common.doSearch = function(e, datatables) {
  all_common.showPreloader('.byg_private__horizForm');

  all_common.showDatatable();
  all_common.reloadDatatables(e, datatables, function() {
    all_common.hidePreloader('.byg_private__horizForm');
    all_common.scrollToElement('.dataTables_wrapper');
  });
  $('.dataTables_wrapper').find('.byg_throbber').addClass('stickyPosition');
};

all_common.getDataColumn = function(row, meta, dataOrder, tableOrder, escapeHtml) {
  var out = 'N/A';
  var index = _.invert(tableOrder)[meta.col];
  if (typeof index === 'undefined') {
    console.warn('No record in Table Order array');
  }
  else {
    var dataIndex = dataOrder[index];
    if (typeof dataIndex === 'undefined') {
      console.warn('No record in Data Order array');
    }
    else {
      out = escapeHtml ? all_common.htmlEscapeEntities(row[dataIndex]) : row[dataIndex];
      if (typeof out === 'boolean') {
        if (out === true) {
          out = bg_globals.i18n.common.yes;
        }
        else {
          out = bg_globals.i18n.common.no;
        }
      }
    }
  }
  return out;
};

all_common.htmlEscapeDataColumnHandler = function(row, type, full, meta) {
  return all_common.htmlEscapeEntities(row[meta.col]);
};

all_common.responiveRecalc = function() {
  $('.dataTable').dataTable().api().responsive.recalc();
};

// endregion DataTables utils

// region Common utils

all_common.show = function(what) {
  $(what).removeClass('hidden');
};
all_common.hide = function(what) {
  $(what).addClass('hidden');
};

/* showPreloader */

all_common.showPreloader = function(where, noDecentHeight) {
  //old version
  //$(where).html(bg_globals.assets.preloader);
  $(where).addClass('rel');
  if (!noDecentHeight) $(where).addClass('decentHeight');
  var mask = '<div class="preloader-mask">';
  mask += bg_globals.assets.preloader_mask;
  mask += '</div>';
  $(where).append(mask);
};

/* hidePreloader */

// where: is the region element containing the preloader
// how  : removes the preloader div from the parent element provided

all_common.hidePreloader = function(where) {
  $(where).find('.preloader-mask').remove();
  $(where).removeClass('rel decentHeight');
};

/* smooth scrolling to element - useful to scroll forms to alerts

 where: css selector
 speed:animation duration in ms
 */
all_common.scrollToElement = function(where, speed) {
  try {
    speed = speed | 500;
    $('html, body').animate({
      scrollTop: $(where).offset().top,
    }, speed);
  } catch (e) {
  }
};

all_common.hideMobileKeybord = function() { // a bit of hacking to force closing on screen keybard on mobile devices
  $(':focus').each(function() {
    hideKeyboard($(this));
  });

  function hideKeyboard($element) {
    //READONLY and DISABLED leads to showing dropdown on autocomplete fields after item chosen
    //$element.attr('readonly', 'readonly'); // Force keyboard to hide on input field.
    //$element.attr('disabled', 'true'); // Force keyboard to hide on textarea field.
    setTimeout(function() {
      $element.blur();  //actually close the keyboard
      // Remove readonly attribute after keyboard is hidden.
      //$element.removeAttr('readonly');
      //$element.removeAttr('disabled');
    }, 100);
  }
};

// HTML sanitizer
all_common.htmlEscapeEntities = function(d) {
  return typeof d === 'string'
      ? d.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
      : d;
};

// endregion Common utils

// region Autocomplete throbber

all_common.autocompleteThrobber = function($input, state) {
  var $throbber = $input.siblings('.autocompleteThrobber');
  if ($throbber.length <= 0) {
    var $parent = $input.closest('.form-group');
    if (!$parent.length) $parent = $input.closest('div');
    $throbber = $parent
        .addClass('rel')
        .append('<div class=\'autocompleteThrobber\'>  <i class=\'fa\'></i></div>');
  }

  var $icon = $throbber.find('i');
  $icon.removeClass('fa-check fa-times fa-spinner fa-spin fa-ban');
  switch (state) {
    case 'reset':
      break;
    case 'busy':
      $icon.addClass('fa-spinner fa-spin');
      break;
    case 'success':
      $icon.addClass('fa-check');
      break;
    case 'fail':
      $icon.addClass('fa-times');
      break;
    case 'noResult':
      $icon.addClass('fa-ban');
      break;
  }

};
// endregion Autocmplete throbber

/** Lock a region to prevent user interaction
 * lock = true disables all inputs
 * lock = false enables all inputs
 * **/
all_common.lockRegion = function(selector, lock) {
  $(selector).find(':input').prop('disabled', lock);
};
/* cookie banner manager. */

$(document).ready(function() {

    // Instances that don't have bg_globals.featureEnabled section will have always cookie banner enabled
    if (!bg_globals.featureEnabled || bg_globals.featureEnabled.cookieBanner) {

        // bg_utils.log('[cookies] document ready, iframe:', bg_globals.iframe_login,
        //     'area_class:', bg_globals.area_class,
        //     'cookiesAccepted:', $.cookie('cookiesAccepted'));

        if (typeof bg_globals.iframe_login === 'undefined' &&
            bg_globals.area_class === 'byg_private'     &&
            $.cookie('pwdExpiring') === 'true') {
            $('#pwdExpirationDate').text($.cookie('pwdExpirationDate'));
            bg_utils.show('.byg_pwdExpiring-banner');
        }

        /* do nothing if:
         * - in iframe-login
         * - in private area
         * - if cookies is already set */

        if (typeof bg_globals.iframe_login !== 'undefined' ||
            bg_globals.area_class === 'byg_private'        ||
            $.cookie('cookiesAccepted') === 'true'
        ) {
            return;
        }

        var acceptCookie = function() {
            if (typeof $.cookie('cookiesAccepted') === 'undefined') {
                $.cookie('cookiesAccepted', true, { 'expires': 365 }); // 365 days
                bg_utils.hide('.byg_cookie-banner');
                bg_utils.log('[cookies] cookies accepted');
            }
            else {
                bg_utils.log('[cookies] cookies already present, nothing to do');
            }
        };

        /* bind event on any element (except more info) */

        $('a:not(".byg_cookie-banner__more-info"), button, input').click(function(e) {
            acceptCookie();
        });

        /* bind event on 'ok' button. Don't let event bubble up to 'document' */

        $('.byg_cookie-banner a.byg_cookie-banner__ok').click(function(e) {
            e.stopPropagation();
            e.preventDefault();
            acceptCookie();
        });

        /* bind event on 'more info' button. Don't let event bubble up to 'document' */

        $('.byg_cookie-banner a.byg_cookie-banner__more-info').click(function(e) {
            e.stopPropagation();
        });

        bg_utils.show('.byg_cookie-banner');
    }
});

  // http://stackoverflow.com/questions/9160123/no-transport-error-w-jquery-ajax-call-in-ie
$.support.cors = true;

function sameOrigin(url) {
    // test that a given url is a same-origin URL
    // url could be relative or scheme relative or absolute
    var host = window.location.host; // host + port
    var protocol = window.location.protocol;
    var sr_origin = '//' + host;
    var origin = protocol + sr_origin;
    // Allow absolute or scheme relative URLs to same origin
    return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
        (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') ||
        // or any other URL that isn't scheme relative or absolute i.e relative.
        !(/^(\/\/|http:|https:).*/.test(url));
}

$.ajaxSetup({
  'beforeSend': function (xhr, settings) {
    if (!/^(GET|HEAD|OPTIONS|TRACE)$/i.test(settings.type) && sameOrigin(settings.url)) {
      xhr.setRequestHeader(bg_globals.csrf_token_key, bg_globals.csrf_token_value);
    }
  },
  'cache': false,
  'jsonp': false,
  // http://stackoverflow.com/questions/2540827/jquery-parameter-serialization-without-the-bracket-mess
  'traditional': true
});

// region Common Document Ready handler

$(document).ready(function() {
  // region Banner Google Analytics
  $('.gaLink').click(function() {
    if (typeof(ga) === typeof(Function)) {
      var label = $(this).attr('title') || $(this).attr('href');
      ga('send', 'event', 'pagosOnlineBanner', 'click', label);
    }
  });
// endregion Banner Google Analytics

  $(document).ready(function() {
    $('[data-toggle="popover"]').popover();
  });

});
// endregion


var bg_consts = bg_consts || {};

bg_consts = {
    'API':          {

        'RESET_PASSWORD':                                         bg_globals.base_url + 'resetPassword',
        'RESET_PASSWORD_BY_PHONE':                                bg_globals.base_url + 'resetPasswordPhone',
        'RESET_PASSWORD_CONFIRM':                                 bg_globals.base_url + 'resetPasswordConfirm',
        'RESET_PASSWORD_BY_PHONE_CONFIRM':                        bg_globals.base_url + 'resetPasswordPhoneConfirm',
        'TRACK_PURCHASE_LIST':                                    bg_globals.base_url + 'trackPurchase',
        'IS_USER_SUSPENDED':                                      bg_globals.base_url + 'blacklistSuspension/isUserSuspended',
        'GET_WHITELISTED_SITES':                                  bg_globals.base_url + 'getWhitelistedServices',
        // CUSTOMER
        'CUSTOMER_REGISTRATION':                                  bg_globals.base_url + 'customerRegistration',
        'CUSTOMER_REGISTRATION_OTP_CONFIRM':                      bg_globals.base_url + 'customerRegistrationOTPConfirm',
        'CUSTOMER_REGISTRATION_OTP_RESEND':                       bg_globals.base_url + 'customerRegistrationOTPResend',
        'CUSTOMER_REGISTRATION_WITH_PHONE':                       bg_globals.base_url + 'customerRegistrationWithPhone',
        'CUSTOMER_REGISTRATION_WITH_PHONE_OTP_CONFIRM':           bg_globals.base_url + 'customerRegistrationWithPhoneOTPConfirm',
        'CUSTOMER_REGISTRATION_RESEND':                           bg_globals.base_url + 'customerRegistrationResend',
        'CUSTOMER_GET_TRANSACTIONS':                              bg_globals.base_url + 'customer/getTransactionsStatus',
        'CUSTOMER_GET_TRANSACTION_BMONITOR_DATA':                 bg_globals.base_url + 'customer/getTransactionsBMonitorData',
        'CUSTOMER_GET_TRANSACTION_DETAIL':                        bg_globals.base_url + 'customer/getTransactionDetails',
        'CUSTOMER_GET_SUBSCRIPTIONS':                             bg_globals.base_url + 'customer/getSubscriptions',
        'CUSTOMER_GET_SUBSCRIPTIONS_BMONITOR_DATA':               bg_globals.base_url + 'customer/getSubscriptionsBMonitorData',
        'CUSTOMER_GET_SUBSCRIPTION_DETAILS':                      bg_globals.base_url + 'customer/getSubscriptionDetails',
        'CUSTOMER_DISCLAIMER_REGISTRATION_RESEND':                bg_globals.base_url + 'customer/customerRegistrationMailResend',
        'CUSTOMER_GET_DETAILS':                                   bg_globals.base_url + 'customer/getCustomerDetails',
        'CUSTOMER_IS_SUSPENDED':                                  bg_globals.base_url + 'customer/isSuspended',
        'CUSTOMER_GET_LIMITS':                                    bg_globals.base_url + 'customer/getCustomerLimits',
        'CUSTOMER_GET_SUSPENSION_HISTORY':                        bg_globals.base_url + 'blacklistSuspension/getCustomerSuspensionHistory',
        'CUSTOMER_SUSPEND_USER':                                  bg_globals.base_url + 'customer/suspendUser',
        'CUSTOMER_UPDATE_DETAILS':                                bg_globals.base_url + 'customer/updateCustomerDetails',
        'CUSTOMER_DELETE_ACCOUNT':                                bg_globals.base_url + 'customer/disableAccount',
        'CUSTOMER_SEARCH_PRODUCT':                                bg_globals.base_url + 'customer/searchProduct',
        'CUSTOMER_UNSUBSCRIBE_USER':                              bg_globals.base_url + 'customer/unsubscribeUser',
        'CUSTOMER_UNSUBSCRIBE_USER_BY_SUB_ID':                    bg_globals.base_url + 'customer/unsubscribeUserBySubId',
        'CUSTOMER_GET_BLACKLISTED_SITES':                         bg_globals.base_url + 'customer/getBlacklistedSites',
        // MERCHANT
        'MERCHANT_REGISTRATION':                                  bg_globals.base_url + 'merchantRegistration',
        'MERCHANT_REGISTRATION_RESEND':                           bg_globals.base_url + 'merchantRegistrationResend',
        'MERCHANT_GET_COMBINATIONS':                              bg_globals.base_url + 'customPE/searchCombinationId',
        'MERCHANT_GET_COMBINATIONS_MT':                           bg_globals.base_url + 'customPE/mtSearchCombinationId',
        'MERCHANT_CREATE_COMBINATION':                            bg_globals.base_url + 'customPE/createCombinationId',
        'MERCHANT_CREATE_COMBINATION_MT':                         bg_globals.base_url + 'customPE/mtCreateCombinationId',
        'MERCHANT_DELETE_COMBINATION':                            bg_globals.base_url + 'customPE/deleteCombinationId',
        'MERCHANT_DELETE_COMBINATION_MT':                         bg_globals.base_url + 'customPE/mtDeleteCombinationId',
        'MERCHANT_PUBLISH_COMBINATION':                           bg_globals.base_url + 'customPE/changeVisibility',
        'MERCHANT_CLONE_COMBINATION':                             bg_globals.base_url + 'customPE/cloneCombination',
        'MERCHANT_CLONE_COMBINATION_MT':                          bg_globals.base_url + 'customPE/mtCloneCombination',
        'MERCHANT_CREATE_STYLE':                                  bg_globals.base_url + 'customPE/createStyleId',
        'MERCHANT_DELETE_STYLE':                                  bg_globals.base_url + 'customPE/deleteStyleId',
        'MERCHANT_ASSOCIATE_STYLE':                               bg_globals.base_url + 'customPE/updateStyleId',
        'MERCHANT_GET_SITES_VIEW':                                bg_globals.base_url + 'merchant/getSitesView',
        'MERCHANT_GET_STYLES':                                    bg_globals.base_url + 'customPE/searchStyleId',
        'MERCHANT_GET_STYLES_MT':                                 bg_globals.base_url + 'customPE/mtSearchStyleId',
        'MERCHANT_GET_STYLEABLE_SITES':                           bg_globals.base_url + 'customPE/getStyleableSites',
        'MERCHANT_GET_STYLE_BY_COMBINATION_ID':                   bg_globals.base_url + 'customPE/mtMerchantSearchStyleByCombinationId',
        'MERCHANT_GET_TRANSACTIONS':                              bg_globals.base_url + 'merchant/getTransactionsStatus',
        'MERCHANT_GET_SUBSCRIPTIONS':                             bg_globals.base_url + 'merchant/getSubscriptions',
        'MERCHANT_GET_SUBSCRIPTIONS_BY_STATUS':                   bg_globals.base_url + 'merchant/getSubscriptionsByStatus',
        'MERCHANT_GET_SUBSCRIPTION_DETAILS':                      bg_globals.base_url + 'merchant/getSubscriptionDetails',
        'MERCHANT_GET_SUBSCRIPTION_TRANSACTIONS':                 bg_globals.base_url + 'merchant/getSubscriptionTransactions',
        'MERCHANT_GET_SUBSCRIPTIONS_BMONITOR_DATA':               bg_globals.base_url + 'merchant/getSubscriptionsBMonitorData',
        'MERCHANT_GET_TRANSACTION_DETAIL':                        bg_globals.base_url + 'merchant/getTransactionDetails',
        'MERCHANT_GET_TRANSACTION_BMONITOR_DATA':                 bg_globals.base_url + 'merchant/getTransactionsBMonitorData',
        'MERCHANT_TRANSACTIONS_B_MONITOR_PAYOUT_DATA':            bg_globals.base_url + 'merchant/getTransactionsBMonitorPayoutData',
        'MERCHANT_GET_SUBSCRIPTION_TRANSACTIONS_FOR_REFUND':      bg_globals.base_url + 'merchant/getSubscriptionTransactionsForRefund',
        'MERCHANT_GET_HOME_BMONITOR_DATA':                        bg_globals.base_url + 'merchant/getHomeBMonitorData',
        'MERCHANT_REFUND_TRANSACTIONS':                           bg_globals.base_url + 'merchant/refundTransactions',
        'MERCHANT_REFUND_V1_TRANSACTIONS':                        bg_globals.base_url + 'merchant/refundV1Transactions',
        'MERCHANT_UNSUBSCRIBE_USER':                              bg_globals.base_url + 'merchant/unsubscribeUser',
        'MERCHANT_UNSUBSCRIBE_USER_BY_SUB_ID':                    bg_globals.base_url + 'merchant/unsubscribeUserBySubId',
        'MERCHANT_UNSUBSCRIBE_V1_USER':                           bg_globals.base_url + 'merchant/unsubscribeV1User',
        'MERCHANT_ACCOUNT_GET_POST_DETAILS':                      bg_globals.base_url + 'merchant/account',
        'MERCHANT_MYACCOUNT_SERVICE':                             bg_globals.base_url + 'merchant/account',
        'MERCHANT_GET_SITES':                                     bg_globals.base_url + 'merchant/getSites',
        'MERCHANT_GET_SETTLEMENTS':                               bg_globals.base_url + 'merchant/getSettlements.json',
        'MERCHANT_CHANGE_PASSWORD':                               bg_globals.base_url + 'merchant/changePassword',
        'MERCHANT_UPDATE_DETAILS':                                bg_globals.base_url + 'merchant/updateDetails',
        'SEND_CONTACT_FORM_MERCHANT':                             bg_globals.base_url + 'sendContactFormMerchant',
        'SEND_CONTACT_FORM_CUSTOMER':                             bg_globals.base_url + 'sendContactFormCustomer',
        //CUSTOMER CARE
        'CUSTOMER_CARE_GET_TRANSACTIONS':                         bg_globals.base_url + 'customerCare/getTransactions',
        'CUSTOMER_CARE_TRANSACTIONS_EXPORT_CSV':                  bg_globals.base_url + 'customerCare/transactionsExportCsv',
        'CUSTOMER_CARE_GET_TRANSACTION_DETAILS':                  bg_globals.base_url + 'customerCare/getTransactionDetails',
        'CUSTOMER_CARE_GET_SUBSCRIPTIONS':                        bg_globals.base_url + 'customerCare/getSubscriptions',
        'CUSTOMER_CARE_SUBSCRIPTIONS_EXPORT_CSV':                 bg_globals.base_url + 'customerCare/subscriptionsExportCsv',
        'CUSTOMER_CARE_GET_SUBSCRIPTION_DETAILS':                 bg_globals.base_url + 'customerCare/getSubscriptionDetails',
        'CUSTOMER_CARE_GET_SUBSCRIPTION_TRANSACTIONS':            bg_globals.base_url + 'customerCare/getSubscriptionTransactions',
        'CUSTOMER_CARE_GET_SUBSCRIPTION_TRANSACTIONS_FOR_REFUND': bg_globals.base_url + 'customerCare/getSubscriptionTransactionsForRefund',
        'CUSTOMER_CARE_REFUND_TRANSACTIONS':                      bg_globals.base_url + 'customerCare/refundTransactions',
        'CUSTOMER_CARE_REFUND_V1_TRANSACTIONS':                   bg_globals.base_url + 'customerCare/refundV1Transactions',
        'CUSTOMER_CARE_UNSUBSCRIBE_USER':                         bg_globals.base_url + 'customerCare/unsubscribeUser',
        'CUSTOMER_CARE_UNSUBSCRIBE_USER_BY_SUB_ID':               bg_globals.base_url + 'customerCare/unsubscribeUserBySubId',
        'CUSTOMER_CARE_UNSUBSCRIBE_V1_USER':                      bg_globals.base_url + 'customerCare/unsubscribeV1User',
        'CUSTOMER_CARE_GET_CUSTOMER_DETAILS':                     bg_globals.base_url + 'customerCare/customer-info',
        'CUSTOMER_CARE_GET_CUSTOMER_LIMITS':                      bg_globals.base_url + 'customerCare/phone-limits',
        'CUSTOMER_CARE_GET_CUSTOMER_NEXT_MONTHLY_LIMIT':          bg_globals.base_url + 'customerCare/phone-next-m-limit',
        'CUSTOMER_CARE_IS_USER_SUSPENDED':                        bg_globals.base_url + 'blacklistSuspension/isUserSuspended',
        'CUSTOMER_CARE_GET_SUSPENSION_HISTORY':                   bg_globals.base_url + 'blacklistSuspension/getSuspensionHistory',
        'CUSTOMER_CARE_SUSPEND_USER':                             bg_globals.base_url + 'blacklistSuspension/suspendUser',
        'CUSTOMER_CARE_INIT_BULK_SUSPEND_USER':                   bg_globals.base_url + 'customerCare/suspendUserBulkInitProcess',
        'CUSTOMER_CARE_RUN_BULK_SUSPEND_USER':                    bg_globals.base_url + 'customerCare/suspendUserBulkRunProcess',
        'CUSTOMER_CARE_DELETE_CUSTOMER':                          bg_globals.base_url + 'customerCare/deleteAccount',
        'CUSTOMER_CARE_GET_BLACKLISTED_SITES':                    bg_globals.base_url + 'blacklistSuspension/getBlacklistedSites',
        'CUSTOMER_CARE_TOGGLE_BLACKLIST':                         bg_globals.base_url + 'blacklistSuspension/toggleBlacklist',
        'CUSTOMER_CARE_TOGGLE_BLACKLIST_MULTI':                   bg_globals.base_url + 'blacklistSuspension/toggleBlacklistMulti',
        'CUSTOMER_CARE_GET_TOKENS_BY_USER':                       bg_globals.base_url + 'customerCare/getTokens',
        'CUSTOMER_CARE_DELETE_TOKENS_BY_USER':                    bg_globals.base_url + 'customerCare/deleteTokens',
        'CUSTOMER_CARE_SHORTENURL':                               bg_globals.base_url + 'customerCare/shortenUrl',
        //BACKOFFICE
        'BACKOFFICE_SEARCH_SITE':                                 bg_globals.base_url + 'backoffice/getSites',
        'BACKOFFICE_SEARCH_BY_VALUE':                             bg_globals.base_url + 'backoffice/searchValue',
        'BACKOFFICE_GET_SITE':                                    bg_globals.base_url + 'backoffice/getSite',
        'BACKOFFICE_INSERT_SITE':                                 bg_globals.base_url + 'backoffice/insertSite',
        'BACKOFFICE_UPDATE_SITE':                                 bg_globals.base_url + 'backoffice/editSite',
        'BACKOFFICE_MANAGE_SITE':                                 bg_globals.base_url + 'backoffice/manageSite',
        'BACKOFFICE_MERCHANT_REGISTRATION':                       bg_globals.base_url + 'backoffice/merchantRegistration',
        'BACKOFFICE_GET_MERCHANT':                                bg_globals.base_url + 'backoffice/getMerchantProfile',
        'BACKOFFICE_UPDATE_MERCHANT':                             bg_globals.base_url + 'backoffice/editMerchant',
        'BACKOFFICE_GET_COMBINATIONS':                            bg_globals.base_url + 'customPE/adminSearchCombinationId',
        'BACKOFFICE_GET_COMBINATIONS_MT':                         bg_globals.base_url + 'customPE/mtAdminSearchCombinationId',
        'BACKOFFICE_GET_STYLES':                                  bg_globals.base_url + 'backoffice/searchStyleByCombinationId',
        'BACKOFFICE_GET_STYLE_BY_COMBINATION_ID':                 bg_globals.base_url + 'customPE/mtSearchStyleByCombinationId',
        'BACKOFFICE_GET_SETTLEMENTS':                             bg_globals.base_url + 'backoffice/getSettlements',
        'BACKOFFICE_UPDATE_COMBINATION':                          bg_globals.base_url + 'customPE/admin/setCombinationIdStatus',
        'PROFILE_CHANGE_PASSWORD':                                bg_globals.base_url + 'profile/changePassword'
    },
    'PAGES':        {
        'MERCHANT_UNCONFIRMED': bg_globals.base_url + 'merchant-unconfirmed',
        'MERCHANT_CONTACT_US':  bg_globals.base_url + 'contact-us-merchant'
    },
    'SIGNUP_FORMS': {
        'PASSWORD_REGEX':/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@!%*?&]{10,64}/,
        'FIRSTNAME_MIN_LEN': 3,
        'LASTNAME_MIN_LEN':  3,
        'PASSWORD_MIN_LEN':  8,
        'COMPANY_MIN_LEN':   3,
        'VAT_MIN_LEN':       8
    },
    'DEBUG':        'false',
    'OPERATIONS':   {
        'REFUND':      'refund_enabled',
        'UNSUBSCRIBE': 'deactivate_sub',
        'EDIT_SITE':   'site_edit',
        'BACKOFFICE':  'backoffice',
        'COUNTRY':     'country_restricted'
    },
    STATUS:         {
        OK: 'OK',
        KO: 'KO'
    }
};

var bg_datatables = bg_datatables || {};

//
// Pipelining function for DataTables. To be used to the `ajax` option of DataTables
//
// (Checks that datatables is loaded, otherwhise we cannot add the "plugin"
if ($.fn.dataTable !== undefined) {

    $.fn.dataTable.pipeline = function ( opts ) {
        // Configuration options
        var conf = $.extend( {
            pages: 5,     // number of pages to cache
            url: '',      // script url
            data: null,   // function or object with parameters to send to the server
                          // matching how `ajax.data` works in DataTables
            method: 'GET' // Ajax HTTP method
        }, opts );

        // Private variables for storing the cache
        var cacheLower = -1;
        var cacheUpper = null;
        var cacheLastRequest = null;
        var cacheLastJson = null;

        return function ( request, drawCallback, settings ) {
            var ajax          = false;
            var requestStart  = request.start;
            var drawStart     = request.start;
            var requestLength = request.length;
            var requestEnd    = requestStart + requestLength;

            if ( settings.clearCache ) {
                // API requested that the cache be cleared
                ajax = true;
                settings.clearCache = false;
            }
            else if ( cacheLower < 0 || requestStart < cacheLower || requestEnd > cacheUpper ) {
                // outside cached data - need to make a request
                ajax = true;
            }
            else if ( JSON.stringify( request.order )   !== JSON.stringify( cacheLastRequest.order ) ||
                JSON.stringify( request.columns ) !== JSON.stringify( cacheLastRequest.columns ) ||
                JSON.stringify( request.search )  !== JSON.stringify( cacheLastRequest.search )
            ) {
                // properties changed (ordering, columns, searching)
                ajax = true;
            }

            // Store the request for checking next time around
            cacheLastRequest = $.extend( true, {}, request );

            if ( ajax ) {
                // Need data from the server
                if ( requestStart < cacheLower ) {
                    requestStart = requestStart - (requestLength*(conf.pages-1));

                    if ( requestStart < 0 ) {
                        requestStart = 0;
                    }
                }

                cacheLower = requestStart;
                cacheUpper = requestStart + (requestLength * conf.pages);

                request.start = requestStart;
                request.length = requestLength*conf.pages;

                // Provide the same `data` options as DataTables.
                if ( $.isFunction ( conf.data ) ) {
                    // As a function it is executed with the data object as an arg
                    // for manipulation. If an object is returned, it is used as the
                    // data object to submit
                    var d = conf.data( request );
                    if ( d ) {
                        $.extend( request, d );
                    }
                }
                else if ( $.isPlainObject( conf.data ) ) {
                    // As an object, the data given extends the default
                    $.extend( request, conf.data );
                }

                settings.jqXHR = $.ajax( {
                    "type":     conf.method,
                    "url":      conf.url,
                    "data":     request,
                    "dataType": "json",
                    "cache":    false,
                    "success"                     :  function ( json ) {
                        cacheLastJson = $.extend(true, {}, json);

                        if ( cacheLower != drawStart ) {
                            json.data.splice( 0, drawStart-cacheLower );
                        }
                        if ( requestLength >= -1 ) {
                            json.data.splice( requestLength, json.data.length );
                        }

                        drawCallback( json );
                    },
                                           'error': bg_utils.apiErrorManager
                } );
            }
            else {
                json = $.extend( true, {}, cacheLastJson );
                json.draw = request.draw; // Update the echo for each response
                json.data.splice( 0, requestStart-cacheLower );
                json.data.splice( requestLength, json.data.length );

                drawCallback(json);
            }
        };
    };

// Register an API method that will empty the pipelined data, forcing an Ajax
// fetch on the next draw (i.e. `table.clearPipeline().draw()`)
    $.fn.dataTable.Api.register('clearPipeline()', function () {
        return this.iterator('table', function (settings) {
            settings.clearCache = true;
        });
    });

}

/* init
 * initialize datatables. Parameters:
 *
 * where: jQuery selector where to render dataTables
 * url: url for ajax call
 * dataFnc: callback function for data manipulation (before send)
 * drawFnc: callback function fired once table has been drawn
 * columnDefs: column rendering
 * moreParams: additional params
 * moreAjaxParams: additional params for ajax
 * moreConfig: additional configs for dtatable
 */



bg_datatables.init = function (where, url, dataFnc, drawFnc, columnDefs, initialOrder, moreParams, moreAjaxParams, moreConfig) {

    if (typeof moreConfig === 'undefined') {
        moreConfig = {};
    }

    _.defaults(moreConfig, {
        serverSide    : true,
        pagingPipeline: true
    });

    var ajaxConf = {
        url : url,
        method: 'POST',
        data: dataFnc,
        pages: 5, // number of pages to cache
        'error': function (xhr, status, err) {
            bg_utils.apiErrorManager(xhr, status, err);
            /* other custom errors here */
        }
    };

    var ajaxHandler;
    if (moreConfig.serverSide && moreConfig.pagingPipeline) {
        ajaxHandler = $.fn.dataTable.pipeline(ajaxConf);
    }
    else {
        ajaxHandler = ajaxConf;
    }


    var params = {
        'serverSide': moreConfig.serverSide,
        'searching': false,
        'processing': true,   // show preloader
        'info': false,
        'pagingType': 'simple_numbers',
        'ajax': ajaxHandler,

        //'drawCallback': drawFnc,
        'order': initialOrder,

        'language': {
            'emptyTable': bg_globals.i18n.table.empty,
            'processing': bg_globals.assets.preloader,
            'lengthMenu': bg_globals.i18n.table.length_menu,
            'paginate': {
                'previous': '&lt;',
                'next': '&gt;'
            }
        },

        'columnDefs': columnDefs
    };

    params = _.extend(params, moreParams);
    if (typeof moreAjaxParams !== 'undefined') {
        params.ajax = _.extend(params.ajax, moreAjaxParams);
    }

    return $(where).DataTable(params);
};


/* initManual
 * initialize datatables by passing manual data to it. */

bg_datatables.initManual = function (where, data, columnDefs, initialOrder, searching) {

    var params = {
        'serverSide': false,
        'searching': searching || false,
        'responsive': true,
        'info': false,
        'data': data,
        'columnDefs': columnDefs,
        'order': initialOrder,
        'language': {
            'emptyTable': bg_globals.i18n.table.empty,
            'processing': bg_globals.assets.preloader,
            'lengthMenu': bg_globals.i18n.table.length_menu,
            'paginate': {
                'previous': '&lt;',
                'next': '&gt;'
            }
        }
    };

    return $(where).DataTable(params);
};

$(document).ready(function() {


  /* click on login button */

  $('.navbar .naviList__login, .naviList__login--mobile, .naviList__username, .navbar-nav__username').
      click(function(ev) {
        ev.stopImmediatePropagation();
        ev.preventDefault();

        if (bg_globals.logged === true) {
          var myAccountPath = $('.navi-myaccount__link').attr('href');
          var myAccountOrDefault = (_.isEmpty(myAccountPath)) ? bg_globals.welcome_page : myAccountPath;
          window.location.replace(myAccountOrDefault);
        } else {
          if ($('.page__loginWrap').is(":visible")) {
            bg_utils.hide('.page__loginWrap');
          }
          else {
            $(".navbar-collapse.collapse.in").collapse('hide');
            bg_utils.show('.page__loginWrap');
            $('.loginFormNameF').focus();
            $('.ie9 .page__loginWrap').css('width', '300px'); // ie9 fix: force iframe redraw

            $('body').off().one('click', function() {
              bg_utils.hide('.page__loginWrap');
            });
          }
        }
      });
  //prevent event bubbling which would close the popup
  $(".loginForm__cont").click(function(e) {
    e.stopPropagation();
  });
  /* submit login */
  $('.loginForm.bygForm').submit(function(e) {
    e.preventDefault();
    var $error = $('.loginForm_err.bygForm__error');
    $error.html('&nbsp;');
    $error.addClass("hidden");

    //bg_utils.showPreloader('.loginForm_err.bygForm__error');
    bg_utils.show('.byg_formMask');

    // Check login username format
    var uid = $('#loginFormNameF').val();
    var processedUid = bg_utils.uidProcessor(uid);

    $.ajax({
      'url': bg_globals.base_url + 'j_spring_security_check',
      'type': 'POST',
      'dataType': 'json', // expected type from server
      'data': {
        'username': processedUid,
        'password': $('#loginFormPasswordF').val(),
        'timezone': new Date().getTimezoneOffset()
      },
      'success': function(data, status, xhr) {
        bg_utils.log('success!');
        bg_utils.log('status: ', status);
        bg_utils.log('data: ', data);
        bg_utils.log('data (str): ', JSON.stringify(data));
        window.top.location.href = bg_globals.base_url + data.redirectUrl; // redirect parent (we are inside an iframe now)
      },
      'error': function(xhr, status, error) {
        bg_utils.log('error!');
        bg_utils.log('status: ', status);
        bg_utils.log('error: ', error);
        bg_utils.log('xhr: ', xhr);

        if (xhr.status === 902) {
          window.top.location.href = bg_consts.PAGES.MERCHANT_UNCONFIRMED;
        }
        else if (xhr.status === 401) {
          window.top.location.reload(true);
        }
        else {
          if (_.has(xhr.responseJSON, 'errorMsg')) {
            $error.html(xhr.responseJSON.errorMsg);
          } else {
            $error.html(bg_globals.i18n.errors.generic_error);
          }
          $error.removeClass("hidden");
        }
      },
      'complete': function() {
        bg_utils.hide('.byg_formMask');
      }
    });
  });
});

$(document).ready(function()
{
  /* click on logout link */

  $('.naviList__logout').click(function() {
    $('.byg_logoutForm').submit();
  });

  /*
  $('.navbar .naviList__logout').click(function() {
    $.ajax({
      'url': bg_globals.base_url + 'perform-logout',
      'type': 'POST',
      'success': function(data, status, xhr) {
        bg_utils.log('success!');
        bg_utils.log('status: ', status);
      },
      'error': function(xhr, status, error) {
        bg_utils.log('logout error!');
        bg_utils.log('xhr:', xhr);
        bg_utils.log('status:', status);
        bg_utils.log('error:', error);
      }
    });
  });*/
});

var bg_utils = bg_utils || {};

/*
 * INSTANCE DEFAULTS
 */
bg_utils.intlPrefix        = '+65';                         // Used to ensure in regex match & replace (see uidProcessor)
bg_utils.intlprefixRegex   = /^((\+65)|(65))/;
bg_utils.simplephoneRegex  = /^(\+)?[0-9]{8,15}$/;          // Phone number with optional leading +
bg_utils.msisdnSearchRegex = /^((\+65)|(65))[0-9]{5,}$/;    // Used when searching a msisdn in trx or subs form.
bg_utils.msisdnRegex       = /^((\+65)|(65))?[0-9]{8,15}$/; // Phone number with optional singapore prefix

/* errorStatusShown (internal use only)
 * Tell whether the alert has been shown or not. */

bg_utils.__errorStatusShown__ = false;

/* handleErrorStatus (internal use only)
 * Print a proper message (and log out) when ajax calls fail. */

bg_utils.__handleErrorStatus__ = function(status) {
  if (status === 401 || status === 403) {
    if (!bg_utils.__errorStatusShown__) {
      alert(bg_globals.i18n.errors.session_error);
      bg_utils.__errorStatusShown__ = true;
    }
    $('.byg_logoutForm').submit();
  }
  else {
    /* show alert with a little delay. The trick prevents useless warnings when
     switching between navigation links too quickly. */
    setTimeout(function() {
      alert(bg_globals.i18n.errors.generic_error);
    }, 3000);
  }
};

/* logger */

bg_utils.log = function() {
  if (bg_consts.DEBUG === "true") {
    try {
      if (arguments.length > 0) for (var arg in arguments) {
        if (arg === undefined) throw new TypeError('LogException: Passing undefined argument');
      }
    }
    catch (err) {
      console.error(err.stack());
    }
    finally {
      try {
        console.log.apply(console, Array.prototype.slice.call(arguments));
      }
      catch (e) {
        console.log(Array.prototype.slice.call(arguments).join('  '));
      }
    }
  }
};

/* loadStaticContent
 * load 'what' content inside 'where' container. */

bg_utils.loadStaticContent = function(what, where) {
  $.ajax({
    'url': what,
    'type': 'GET',
    'beforeSend': function() {
      $(where).html(bg_globals.assets.preloader);
    },
    'success': function(data, status, xhr) {
      bg_utils.log('success on get', what);
      $(where).html(data);
    },
    'error': function(xhr, status, error) {
      bg_utils.log('error!', status);
      $(where).html('unable to fetch remote data');
    }
  });
};

/* preloadImages
 * preload images. Usage: preloadImages(img1, img2, ..., imgN) */

bg_utils.preloadImages = function() {
  for (var i = 0; i < arguments.length; i++) {
    var img = $("<img />").attr("src", arguments[i]);
    bg_utils.log('[bg_utils::preloadImages] image "%s" preloaded', img.attr('src'));
  }
};

/* timezone
 * process 'date' with timezone taken into account. */

bg_utils.timezone = function(rawDate) {
  return '(TODO timezone) --- ' + rawDate;
};

/* apiErrorManager
 * general error manager for private API calls. */

bg_utils.apiErrorManager = function(xhr, status, err) {
  bg_utils.log('API ERROR!');
  bg_utils.log('status:', status);
  bg_utils.log('error:', err);
  bg_utils.__handleErrorStatus__(xhr.status);
};

/* backboneApiErrorManager
 * same as above, but customized for Backbone. */

bg_utils.backboneApiErrorManager = function(model, response, options) {
  bg_utils.log('BACKBONE API ERROR!');
  bg_utils.log('model:', model);
  bg_utils.log('response:', response);
  bg_utils.log('options:', options);
  bg_utils.__handleErrorStatus__(response.status);
};

/* getParameterByName
 * return the value of 'name' parameter, from GET querystring. */

bg_utils.getParameterByName = function(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

/* show|hide
 * remove or add hidden' class by Bootstrap from an element 'what'. */

bg_utils.show = function(what) {
  $(what).removeClass('hidden');
};
bg_utils.hide = function(what) {
  $(what).addClass('hidden');
};

/* same as above, but takes in input a jQuery object */

bg_utils.showEl = function(what) {
  what.removeClass('hidden');
};
bg_utils.hideEl = function(what) {
  what.addClass('hidden');
};

/* format
 * format a string. */

bg_utils.format = function() {
  var s = arguments[0];
  for (var i = 0; i < arguments.length - 1; i++) {
    var reg = new RegExp("\\{" + i + "\\}", "gm");
    s = s.replace(reg, arguments[i + 1]);
  }
  return s;
};

/* showPreloader */

bg_utils.showPreloader = function(where, noDecentHeight) {
  //old version
  //$(where).html(bg_globals.assets.preloader);
  $(where).addClass('rel');
  if (!noDecentHeight) $(where).addClass('decentHeight');
  var mask = '<div class="preloader-mask">';
  mask += bg_globals.assets.preloader_mask;
  mask += '</div>';
  $(where).append(mask);
};

/* hidePreloader */

// where: is the region element containing the preloader
// how  : removes the preloader div from the parent element provided

bg_utils.hidePreloader = function(where) {
  $(where).find('.preloader-mask').remove();
  $(where).removeClass('rel decentHeight');
};

/* smooth scrolling to element - useful to scroll forms to alerts

 where: css selector
 speed:animation duration in ms
 */
bg_utils.scrollToElement = function(where, speed) {
  speed = speed | 500;
  try {
    speed = speed | 500;
    $('html, body').animate({
      scrollTop: $(where).offset().top
    }, speed);
  } catch (e) {
  }
};

/**
 * from (developer.mozilla) : The Element.scrollIntoView() method scrolls the element on which it's called into the visible area of the browser window.
 * @param element
 */
bg_utils.scrollInView = function(element) {
  try {
    element[0].scrollIntoView();
  }
  catch (e) {}

};

/* hasOperation
 * Check whether the operation 'op' is present in the global vars. */

bg_utils.hasOperation = function(op) {
  for (var i = 0; i < bg_globals.operations.length; i++) {
    if (bg_globals.operations[i] === op) {
      return true;
    }
  }
  return false;
};

/*  username(mobileNumbr) format detector
 * Handles the different mobile phone number formats (intl. / local) */

bg_utils.uidProcessor = function(uid) {
  var matches = uid.match(bg_utils.msisdnRegex);
  var res = uid;
  if (matches !== null && matches[1] !== null)
    res = uid.replace(matches[1], ''); //has prefix
  return res;
};

/* Date time to milliseconds converter
 ** Accepted format is dd/mm/yyyy hh:mm  */

bg_utils.dateToUTC = function(dateTime) {
  // Check format
  dateTime.match(/^[0,1][0-9]\/[0-2][0-9]\/[0-9]{4}\s(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/);
  // Split values
  var y = dateTime.substr(6, 4);
  var m = dateTime.substr(3, 2);
  var d = dateTime.substr(0, 2);
  var h = dateTime.substr(11, 2);
  var mm = dateTime.substr(14, 2);
  // Return milliseconds
  return Date.UTC(y, m, d, h, mm);
};

/* Add popover to element (input)
 */

bg_utils.addPopover = function(where, title, text) {
    bg_utils.log('Adding popover to element ' + where);
  $(where).popover({
      container:'body',
    title: title,
    content: text,
    placement: 'auto'
  }).blur(function() {
    $(this).popover('destroy');
  });
};

/**
 * Shows a popover that will fade out after a certain amount of time
 * @param element that has an attached popover (defined in the html, or with the abouve bg_utils.addPopover method)
 * @param delay time in ms after that the popover will fade out
 */
bg_utils.showTimedPopower = function(element, delay) {
    bg_utils.log('* Showing the timed popover');
    $(element).popover("show");

    function destroyPopover() {
        $(element).popover("destroy");
    }

    setTimeout(destroyPopover, delay);
};

/**
 * @param inputString some text
 * @param regex to compare with
 * @returns {*} (boolean) true|false
 */
bg_utils.strRegexCompare = function(inputString, regex) {
    var validated = regex.test(inputString);
    bg_utils.log('String to regex comparison returned: ' + validated);
    return validated;
};


/**
 * When 'event' on the selected element, show a popover if the validation rule is not satisfied
 * @param triggerElement the element where the triggerin event is attached
 * @param popoverElement where the popover is attached and will be shown
 * @param eventType the type of event that will trigger this process (like click on triggerElement)
 */
bg_utils.initMsisdnValidator = function (triggerElement, popoverElement, eventType) {

    // Actually I'm managing this type of events, if you want to reuse the function
    // with a new type, be sure that the behaviour its handled correctly for that type.
    if (eventType !== 'click' && eventType !== 'change') {
        bg_utils.log('Event assigned to triggerElement is not managed. Please add the correct implementation: ' + eventType);
        return;
    }

    $(triggerElement).on(eventType, function () {

        bg_utils.msisdnValidate(popoverElement);
    })
};

bg_utils.msisdnValidate = function(popoverElement) {
    var msisdnValue = $(popoverElement).val();

    // Don't validate any empty field
    if (msisdnValue.length === 0) return;

    // Validation here
    if(bg_utils.strRegexCompare(msisdnValue, bg_utils.msisdnSearchRegex) === false) {
        bg_utils.log('The provided user id is not satifying the validation rule');
        bg_utils.addPopover(popoverElement, 'Information', 'Please add +65');
        bg_utils.showTimedPopower(popoverElement, 4000);
    }
};

/* format data as '-' when empty */
bg_utils.prettyFormatData = function(data) {
  if (typeof data !== 'undefined' && data !== '' && data !== null) {
    return data;
  }
  return '-';

};

/* Mobile number formatter:
 * Input: Accepts different msisdn formats +6512345678 | 6512345678 | 12345678
 * Out:   Returns a MSISDN WITHOUT the leading international prefix (Added later by Spring, coming from selected country code, in this case SG +65)
 */
bg_utils.msisdnUIFormatter = function (rawNumber) {
    return rawNumber.match(bg_utils.msisdnRegex) ? rawNumber.replace(bg_utils.prefixRegex, '') : rawNumber;
};


bg_utils.captchaReset = function() {
  console.log('captchaReset');
  console.log(typeof grecaptcha);
  if (typeof grecaptcha !== 'undefined' && typeof grecaptcha.reset !== 'undefined') {
      grecaptcha.reset();
  }
  $("input[name=captcha]").val('');
};

bg_utils.initCaptcha = function($input) {
  $input = $input || $("input[name=captcha]");
  if ($input.length < 1) {
    bg_utils.log("No Captcha inputs found.");
    return false;
  }
  $input.addClass("recaptchaHidden");
  var $div = $("<div class='g-recaptcha'></div>");
  $div.attr('data-sitekey', bg_globals.recaptcha_siteKey);
  $div.attr('data-callback', 'recaptchaCallback');
  $div.attr('data-expired-callback', 'recaptchaCallback');
  $input.before($div);
};
bg_utils.initCaptchaInViews = function($input) {

    bg_utils.initCaptcha($input);

	  $.getScript('https://www.google.com/recaptcha/api.js?hl='+bg_globals.language, function() {});

	};

var recaptchaCallback = function() {
  bg_utils.log('recaptchaCallback');
  if (typeof grecaptcha !== 'undefined') {
    var response = grecaptcha.getResponse(),
        $input   = $("input[name=captcha]");
    console.log('response,$input');
    console.log(response,$input);
    $input.length && $input.val(response || '') && $input.keyup();
  }
};
var bg_validator = bg_validator || {};

/* validate
 * setup jquery validator and bind it to an element. Parameters:
 *
 * what: jquery selector to bind validator to
 * apiUrl: api endpoint for form submission (if valid)
 * messages: custom i18n messages
 * rules: how to walidate fields
 * alertBox: where to put alert message (jquery selector)
 * successFnc: callback fired when submission is ok */

bg_validator.validate = function (what, apiUrl, messages, rules, alertBox, successFnc, validatorInstance) {

    /* TODO: move rules and override outside function */
    /* custom rule for pattern matching */

    jQuery.validator.addMethod('pattern', function (value, element, param) {
        return this.optional(element) || param.test(value);
    });

    // Method for AU Mobile Phone Validation, if format is wrong, returns null

    bg_validator.addCustomRules();
    /* override default messages */

    jQuery.extend(jQuery.validator.messages, {
        'required': bg_globals.i18n.form.errors.missing_field,
        'email':    bg_globals.i18n.form.errors.wrong_email
    });

    return $(what).validate({
        'messages':      messages,
        'rules':         rules,
        'errorElement':  'span',
        'errorClass':    'bygForm__error',
        'submitHandler': function (form) {

            if (typeof apiUrl === 'undefined')
                return;

            /* hide and clean up error box and show form mask with preloader */

            bg_utils.hide(alertBox);
            $(alertBox).find('.byg_msg').html();
            bg_utils.show('.byg_formMask');

            $.ajax({
                'url':      apiUrl,
                'type':     'POST',
                'dataType': 'json', // expected type from server
                'data':     $(form).serialize(),
                'success':  function (data, status, xhr) {

                    bg_utils.log('xhr success!');
                    bg_utils.log('status: ', status);
                    bg_utils.log('data: ', data);

                    if (data.status === 'OK') {
                        successFnc(data.status);
                    }
                    else {
                        // TODO - use bg_validator.printErrors (below)
                        bg_utils.log('nope, found some errors!');
                        if (data.errorMsg !== undefined) {
                            bg_utils.show(alertBox);
                            $(alertBox).find('.byg_msg').html(data.errorMsg);
                        }
                        if (data.fields !== undefined) {
                            var errors = {};
                            for (var i = 0; i < data.fields.length; i++) {
                                bg_utils.log(data.fields[i].errorMsg.join(', '));
                                errors[data.fields[i].fieldName] = data.fields[i].errorMsg.join(', ');
                            }
                            if (typeof validatorInstance === 'function') {
                                validatorInstance().showErrors(errors);
                            }
                        }
                    }
                },
                'error':    function (xhr, status, error) {
                    // erorr managment here\
                    bg_utils.apiErrorManager(xhr, status, error);
                },
                'complete': function () {
                    bg_utils.hide('.byg_formMask');
                }
            });
        }
    });
};

/* validateV2
 * setup jquery validator and bind it to an element. Parameters:
 *
 * what                : jquery selector to bind validator to
 * apiUrl              : api endpoint for form submission (if valid)
 * messages            : custom i18n messages
 * rules               : how to walidate fields
 * alertBox            : where to put alert message (jquery selector)
 * successFnc          : callback fired when submission is ok
 *
 * extraOb             : extra params
 * Function beforeSend : called before the actual submit. will be passed a reference to the form,
 * and an obj with a copy of the validate
 * function arguments. If it returns false, the submit will not happen.
 */

bg_validator.validateV2 = function (what, apiUrl, messages, rules, alertBox, successFnc, extraOb) {

    var paramsOb = {
        what:       what,
        apiUrl:     apiUrl,
        messages:   messages,
        rules:      rules,
        alertBox:   alertBox,
        successFnc: successFnc,
        extraOb:    extraOb
    };

    /* TODO: move rules and override outside function */
    /* custom rule for pattern matching */

    jQuery.validator.addMethod('pattern', function (value, element, param) {
        return this.optional(element) || param.test(value);
    });

    /* override default messages */

    jQuery.extend(jQuery.validator.messages, {
        'required': bg_globals.i18n.form.errors.missing_field,
        'email':    bg_globals.i18n.form.errors.wrong_email
    });

    bg_validator.addCustomRules();

    var validatorSettings = {
        'messages':      paramsOb.messages,
        'rules':         paramsOb.rules,
        'errorElement':  'span',
        'errorClass':    'bygForm__error',
        'submitHandler': function (form) {
            /* copy of the validator arguments*/
            var paramsObCp = _.clone(paramsOb);
            if (paramsObCp.extraOb && typeof paramsObCp.extraOb.beforeSend === 'function') {
                if (paramsObCp.extraOb.beforeSend(form, paramsObCp) === false) {
                    return;
                }
            }

            if (typeof paramsObCp.apiUrl === 'undefined') {
                return;
            }

            /* hide and clean up error box and show form mask with preloader */

            bg_utils.hide(paramsObCp.alertBox);
            $(paramsObCp.alertBox).find('.byg_msg').html();
            bg_utils.show('.byg_formMask');

            $.ajax({
                'url':      paramsObCp.apiUrl,
                'type':     'POST',
                'dataType': 'json', // expected type from server
                'data':     $(form).serialize(),
                'success':  function (data, status, xhr) {

                    bg_utils.log('xhr success!');
                    bg_utils.log('status: ', status);
                    bg_utils.log('data: ', data);

                    if (data.status === 'OK') {
                        paramsObCp.successFnc(paramsObCp);
                    }
                    else {
                        // TODO - use bg_validator.printErrors (below)
                        bg_utils.log('nope, found some errors!');
                        if (data.errorMsg !== undefined) {
                            bg_utils.show(paramsObCp.alertBox);
                            $(paramsObCp.alertBox).find('.byg_msg').html(data.errorMsg);
                        }
                        if (data.fields !== undefined) {
                            var errors = {};
                            for (var i = 0; i < data.fields.length; i++) {
                                bg_utils.log(data.fields[i].errorMsg.join(', '));
                                errors[data.fields[i].fieldName] = data.fields[i].errorMsg.join(', ');
                            }
                            validator.showErrors(errors);
                        }
                    }
                },
                'error':    function (xhr, status, error) {
                    // erorr managment here\
                    bg_utils.apiErrorManager(xhr, status, error);
                },
                'complete': function () {
                    bg_utils.hide('.byg_formMask');
                }
            });
        }
    };

   if(paramsOb.extraOb && typeof paramsOb.extraOb.errorPlacement==='function'){
     validatorSettings = _.extend(validatorSettings,{'errorPlacement':paramsOb.extraOb.errorPlacement});
   }
   var ref = $(paramsOb.what).validate(validatorSettings);
   return ref;
};

bg_validator.addCustomRules = function () {

    jQuery.validator.addMethod('only_letters', function (value, element, param) {
        return this.optional(element) || /^[a-z\u00C0-\u017F\s]+$/i.test(value);
    }, bg_globals.i18n.form.errors.only_letters_pattern);

    jQuery.validator.addMethod('valueSelected', function (value, element, arg) {
        return value > 0;
    }, bg_globals.i18n.form.errors.missing_field);

    /* validator for australian phone */

    jQuery.validator.addMethod('phone_au', function (value, element, param) {
        return this.optional(element) || /^0[0-9]{9}$/.test(value);
    }, bg_globals.i18n.form.errors.phone_customer);

    jQuery.validator.addMethod('phone_au_multiformat', function (value, element, param) {
        return this.optional(element) || /^((\+61)|(\+610)|0)?[0-9]{9}$/.test(value);
    }, bg_globals.i18n.form.errors.phone_customer);

};

/* unbind
 * remove validator from 'what' (jquery selector). */

bg_validator.unbind = function (what) {
    $(what).removeData('validator');
};


/* printErrors
 * print all errors from 'data', which is the error object returned from the
 * server. */

bg_validator.printErrors = function (validator, data, alertBox) {
    bg_utils.log(alertBox);
    if (data.errorMsg !== undefined && alertBox !== undefined) {
        bg_utils.show(alertBox);
        $(alertBox).find('.byg_msg').html(data.errorMsg);
    }
    if (data.fields !== undefined) {
        var errors = {};
        for (var i = 0; i < data.fields.length; i++) {
            errors[data.fields[i].fieldName] = data.fields[i].errorMsg.join(', ');
        }
        validator.showErrors(errors);
    }
};

